import { randomNum } from './random_num';

const mimeTypes = {
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  pjpeg: 'image/pjpeg',
  png: 'image/png',
  apng: 'image/apng',
  'x-png': 'image/x-png',
  gif: 'image/gif',
  webm: 'video/webm',
  mp4: 'video/mp4',
};

export const isImageFile = (file) => {
  if (!file) return false;
  if (file.type.includes('svg')) return false;
  return file.type ? file.type.split('/')[0] === 'image' : false;
};

/**
 * Create a blob from a dataURI string.
 *
 * @param  {String}  dataURI
 * @param  {Object}  opts
 * @param  {[type]}  toFile
 *
 * @return {String}
 */
export const dataURItoBlob = (dataURI, opts, toFile) => {
  // get the base64 data
  const data = dataURI.split(',')[1];

  // user may provide mime type, if not get it from data URI
  let mimeType = opts.mimeType || dataURI.split(',')[0].split(':')[1].split(';')[0];

  // default to plain/text if data URI has no mimeType
  if (mimeType == null) {
    mimeType = 'plain/text';
  }

  const binary = atob(data);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i));
  }

  // Convert to a File?
  if (toFile) {
    return new File([new Uint8Array(array)], opts.name || '', { type: mimeType });
  }

  return new Blob([new Uint8Array(array)], { type: mimeType });
};

/**
 * Create a file from a dataURI string.
 *
 * @param  {String}  dataURI
 * @param  {Object}  opts
 *
 * @return {String}
 */
export const dataURItoFile = (dataURI, opts) => this.dataURItoBlob(dataURI, opts, true);

/**
 * Save a <canvas> element's content to a Blob object.
 *
 * @param {HTMLCanvasElement} canvas
 * @return {Promise}
 */
export const canvasToBlob = (canvas, type, quality) => {
  if (canvas.toBlob) {
    return new Promise((resolve) => {
      canvas.toBlob(resolve, type, quality);
    });
  }

  return Promise.resolve().then(() => this.dataURItoBlob(canvas.toDataURL(type, quality), {}));
};

/**
* Takes a full filename string and returns an object {name, extension}
*
* @param {string} fullFileName
* @return {object} { name, extension }
*/
export const getFileNameAndExtension = (fullFileName) => {
  const re = /(?:\.([^.]+))?$/;
  const fileExt = re.exec(fullFileName)[1];
  const fileName = fullFileName.replace(`.${fileExt}`, '');

  return {
    name: fileName,
    extension: fileExt,
  };
};

/**
* Takes a file and returns the file mimetype.
*
* @param {string} fullFileName
* @return {object} { name, extension }
*/
export const getFileType = (file) => {
  let fileExtension = file.name ? getFileNameAndExtension(file.name).extension : null;
  fileExtension = fileExtension ? fileExtension.toLowerCase() : null;

  // check if mime type is set in the file object
  if (file.type) {
    return file.type;
  }

  // see if we can map extension to a mime type
  if (fileExtension && mimeTypes[fileExtension]) {
    return mimeTypes[fileExtension];
  }

  // if all fails, fall back to a generic byte stream type
  return null;
};

/**
 * Takes a file object and turns it into fileID, by converting file.name to lowercase,
 * removing extra characters and adding type, size and lastModified
 *
 * @param {Object} file
 * @return {String} the fileID
 *
 */
export const generateFileID = () => randomNum(10);

/**
 * Convert Bytes to human readable values.
 *
 * @param {Number} bytes
 * @param {String} seperator Optional
 * @return {String}
 *
 */
export const bytesToSize = (bytes, seperator = '') => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
  if (i === 0) return `${bytes}${seperator}${sizes[i]}`;
  return `${+(bytes / (1024 ** i)).toFixed(1)}${seperator}${sizes[i]}`;
};

/**
 * Get media type base of file mimeType.
 *
 * @param  {String}  fileType  File mimyType
 * @return {String}  [img, video, null]
 */
export const getMediaType = (fileType) => {
  if (!fileType) return null;

  if (/image\//.test(fileType)) {
    return 'img';
  }
  if (/video\//.test(fileType)) {
    return 'video';
  }

  return null;
};

/**
 * Get element measurememnts, width, height, aspect ratio and orientation.
 *
 * @param  {DOM Element}  element      A loaded DOM element.
 * @param  {String}       elementType  The element type [img, video]
 * @return {Object}
 */
export const getElementMeasurements = (element, elementType, uploadDimensions) => {
  const width = elementType === 'video' ? element.videoWidth : element.naturalWidth;
  const height = elementType === 'video' ? element.videoHeight : element.naturalHeight;
  const ratio = (width / height).toFixed(2);
  const defaultRatio = uploadDimensions ? (uploadDimensions.minWidth / uploadDimensions.minHeight).toFixed(2) : null;
  const isExactRatio = ratio === defaultRatio;
  const optimal = width >= 1600 && height >= 1200;
  const subOptimal = width < 1600 || height < 1200;

  return {
    width,
    height,
    orientation: width > height ? 'landscape' : 'portrait',
    optimal,
    optimalExactRatio: optimal && isExactRatio,
    subOptimal,
    subOptimalExactRatio: subOptimal && isExactRatio,
    ratio,
  };
};

/**
 * Get measurememnts from width, height
 *
 * @param  {Number}  width
 * @param  {Number}  height
 * @param  {Object}  uploadDimensions
 * @return {Object}
 */
export const getMeasurementsFromSize = (width, height, uploadDimensions) => {
  const ratio = width / height;
  const isExactRatio = ratio === (uploadDimensions.minWidth / uploadDimensions.minHeight);
  const optimal = width >= 1600 && height >= 1200;
  const subOptimal = width < 1600 || height < 1200;

  return {
    width,
    height,
    orientation: width > height ? 'landscape' : 'portrait',
    optimal,
    optimalExactRatio: optimal && isExactRatio,
    subOptimal,
    subOptimalExactRatio: subOptimal && isExactRatio,
    ratio,
  };
};

/**
 * Check if file is an animated gif.
 *
 * @param  {String}      type     MineType for the file.
 * @param  {String}      dataUrl  The dataURI string.
 * @return {Boolean}
 */
export const isAnimatedGif = (type, dataUrl) => {
  if (/image\/gif/.test(type) === false) return false;

  const data = atob(dataUrl.replace('data:image/gif;base64,', ''));
  let frameCount = 0;

  // Count frame headers, which are a static 4-byte sequence (\x00\x21\xF9\x04),
  // 4 variable bytes, and a static 2-byte sequence (\x00\x2C) (or \x00\x21).
  // eslint-disable-next-line no-control-regex
  data.replace(/\x00\x21\xF9\x04.{0,4}\x00[\x2c\x21]/g, () => {
    frameCount += 1;
  });

  return frameCount > 1;
};

export default {
  dataURItoBlob,
  dataURItoFile,
  canvasToBlob,
  getFileType,
  getFileNameAndExtension,
  generateFileID,
  bytesToSize,
  getMediaType,
  getElementMeasurements,
  getMeasurementsFromSize,
  isAnimatedGif,
};
